<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn class="ma-2" dark color="danger" @click="nuevocaseta">
                            <v-icon>mdi-plus</v-icon>
                           Nueva Caseta
                        </v-btn>
                     <!--   <div>
                            <v-btn class="ma-2" color="primary">
                                <v-icon>mdi-cog</v-icon>
                            </v-btn>
                            <v-btn outlined class="ma-2">Import</v-btn>
                            <v-btn outlined class="ma-2">Export</v-btn>
                        </div>-->
                    </div>
                </v-card-title>
                <v-card-title>
                    Casetas
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Búsqueda"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                 locale="es"
                    :search="search"
                    :headers="headers"
                    :items="lista_casetas"
                    item-key="idvia"
                    
                    class="elevation-1 table-one"
                    multi-sort
                >
                <template v-slot:item.ine="{ item }">
    <a style="cursor: pointer" @click="ver_imagen(item.ine)">{{
                      item.ine
                    }}</a>
  </template>
      <template v-slot:item.licencia="{ item }">
    <a style="cursor: pointer" @click="ver_imagen(item.licencia)">{{
                      item.licencia
                    }}</a>
  </template>
     <template v-slot:item.examen_medico="{ item }">
    <a style="cursor: pointer" @click="ver_imagen(item.examen_medico)">{{
                      item.examen_medico
                    }}</a>
  </template>
 
 

                    <template v-slot:item.action="{item}">

                        <div class="d-flex">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                    @click="editcaseta(item.idcaseta)"
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-pencil-box-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="danger"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        @click="eliminarcaseta(item)"
                                    >
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Eliminar</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.badge="{item}">
                      <!--  <template v-if="item.badge === 'Active'">
                            <v-chip
                                class=""
                                color="success"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-check</v-icon>
                                {{ item.badge }}
                            </v-chip>
                        </template>
                        <template v-else>
                            <v-chip
                                class=""
                                color="danger"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-close</v-icon>
                                {{ item.badge }}
                            </v-chip>
                        </template>-->
                    </template>
                </v-data-table>
            </base-card>
        </v-col>
    </v-row>
</template>

<script>
import { url } from "../../main.js";

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'casetas'
    },
    data() {
    return {
          search: '',
           // selected: [],
            headers: [
                {
                    text: 'caseta',
                    align: 'start',
                    sortable: false,
                    value: 'caseta'
                },
                {text: 'km', value: 'km'},
                {text: '3 Ejes', value: 'tres_ejes'},
                {text: '5 Ejes', value: 'cinco_ejes'},
                {text: '9 Ejes', value: 'nueve_ejes'},

                {text: 'Acción', value: 'action'}
            ],
     
      edit: false,
      permiso: 0,
     
      caseta: {
        idcaseta: 0,

        via: "",
        km: "",
        vigencia: '',
        tres_ejes:0,
        cinco_ejes:0,
        nueve_ejes:0
       
      },
      
      lista_casetas: [],
    };
  },
 
  mounted() {
    this.vercasetas();
  },
  

  methods: {
    vercasetas() {
      this.axios
        .get(url + "apiVias.php/casetas")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_casetas = respuesta.data;

          console.log(this.lista_casetas);
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
    savecaseta() {
      
    },nuevocaseta(){
       this.$router.push("/catalogos/caseta/0");


    },
  
  

   
    editcaseta(idcaseta) {
    
             this.$router.push("/catalogos/caseta/"+idcaseta);

    },
    eliminarcaseta(caseta) {
      this.$swal({
        title: "Confirmación",
        text: "¿Desea eliminar al caseta?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, eliminar",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(url + "apiVias.php/delete_caseta", caseta)
            .then((respuesta) => {
              console.log(respuesta);

              if (respuesta.data.status === 200) {
                this.$swal("Caseta Eliminada");
                this.vercasetas();
              }
            });
        }
      });
    },

    getidcaseta(e) {
      if (e.target.options.selectedIndex > -1) {
        this.caseta.idcaseta = this.lista_casetas[
          e.target.options.selectedIndex
        ].idcaseta;
      }
    },
    get_casetas() {
      this.axios
        .get(url + "apicasetas.php/casetas")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_casetas = respuesta.data;

          console.log(this.lista_casetas);
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
  

   

    cancelarEdicion() {
      this.edit = false;
      this.limpiarcampos();
    }
   
  }
}
</script>
