var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"danger"},on:{"click":_vm.nuevocaseta}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Nueva Caseta ")],1)],1)]),_c('v-card-title',[_vm._v(" Casetas "),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"locale":"es","search":_vm.search,"headers":_vm.headers,"items":_vm.lista_casetas,"item-key":"idvia","multi-sort":""},scopedSlots:_vm._u([{key:"item.ine",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.ver_imagen(item.ine)}}},[_vm._v(_vm._s(item.ine))])]}},{key:"item.licencia",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.ver_imagen(item.licencia)}}},[_vm._v(_vm._s(item.licencia))])]}},{key:"item.examen_medico",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.ver_imagen(item.examen_medico)}}},[_vm._v(_vm._s(item.examen_medico))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.editcaseta(item.idcaseta)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""},on:{"click":function($event){return _vm.eliminarcaseta(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)]}},{key:"item.badge",fn:function(ref){
var item = ref.item;
return undefined}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }